import { useLayoutEffect } from 'react'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'

const $root = document.getElementById('root')

export function useBodyScrollLock(locked = false, target = $root) {
  useLayoutEffect(() => {
    if (!target) return

    if (locked) {
      disableBodyScroll(target, {
        // 加上 scrollable 类名的元素可以滚动
        allowTouchMove: (el) => {
          while (el && el !== document.body) {
            if (el.classList.contains('scrollable')) {
              return true
            }

            el = el.parentElement
          }
        },
      })
    } else {
      enableBodyScroll(target)
    }

    return () => {
      clearAllBodyScrollLocks()
    }
  }, [locked, target])
}
