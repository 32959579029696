import fetch from '../utility/fetcher'
import { cRequest } from '../utility/http-instance'

export type ShopMessageParams = {
  ShopID: string
  Content: string
  OrderCode?: string
}

/**
 * 添加留言
 */
export const createShopMessage = (data: ShopMessageParams) =>
  fetch({
    Url: 'Shop.AddGuestbook',
    Key: localStorage.getItem('c_token'),
    ...data,
  })

export type InviteCodeInfo =
  | {
      code_type: 6
      invite_code: string
      invitee_phone: string
      invitee_province: number
      invitee_city: number
      invitee_area: number
    }
  | {
      code_type: Exclude<number, 6>
      invite_code: string
    }

/**
 * 判断是否为完整的邀请码信息
 */
export function isFullInviteCodeInfo(
  info: InviteCodeInfo
): info is Extract<InviteCodeInfo, { code_type: 6 }> {
  return info.code_type === 6
}

/**
 * 获取邀请码信息
 */
export function getInviteCodeInfo(inviteCode: string) {
  return cRequest.post<{ invite_code_info: InviteCodeInfo | null }>(
    'Shop.InviteCode',
    { InviteCode: inviteCode }
  )
}
